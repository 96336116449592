import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import Vank from "@/assets/Icon/Landing/vanklogoYellow.png";
import WordLenguaje from "@/assets/Icon/WordLenguaje.svg";
import { FlagIcon } from "react-flag-kit";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [t, i18n] = useTranslation("global");

  const menuRef = useRef<any>(null);

  const handleMenuToggler = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navItems = [
    { path: "/", title: t("Landing.Header.home") },
    { path: "/Companies", title: t("Landing.Header.companies") },
    { path: "/People", title: t("Landing.Header.people") },
    { path: "/VankCards", title: t("Landing.Header.vankCards") },
    { path: "/VankVersity", title: "VankVersity" },
  ];

  const toogleLenguaje = (len: string) => {
    // const leng = i18n.language === "en" ? "es" : "en";

    i18n.changeLanguage(len);
    // localStorage.setItem("lenguaje", len);
  };

  useEffect(() => {
    function handleClickOutside(event?: any) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenu(false);
        return;
      }
    }

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.addEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <header className="fixed top-0 left-0 w-full h-[82px] flex justify-center items-center  bg-[#161616CC] z-50">
      <nav className="w-full flex justify-around items-center py-4 bg-transparent">
        {/* <div className="flex items-center gap-20"> */}
        <div className="w-[117px] h-[29px] flex justify-start items-center">
          <a href="/" className="w-[84px] h-[29px]">
            <img src={Vank} className="w-full h-full object-cover" />
          </a>
        </div>

        {/* nav items for large devices */}
        <ul className="w-[515px] hidden md:flex gap-[40px] items-center">
          {navItems.map(({ path, title }, index) => (
            <li
              key={index}
              className="text-base text-[var(--text-body)] relative group"
            >
              <NavLink to={path}>
                {({ isActive, isPending, isTransitioning }) => (
                  <div className="relative">
                    <span className={`${isActive ? "text-[#FFF133]" : "text-[#FFFFFF]"} text-[15px] opacity-100 font-[500] transition-colors duration-300 leading-[24px]`}>{title}</span>
                    <span
                      className={`absolute left-0 bottom-0 h-[2px] bg-[#FFF133] transition-all duration-300
                      ${isActive ? "w-full" : "w-0 group-hover:w-full"}`}
                    ></span>
                  </div>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
        {/* </div> */}

        {/* sing and login btn */}
        {/* <div className="flex items-center gap-x-6 pr-2"> */}
        {/* <div className=""> */}
        {/* <Link
              to="/Auth/SingIn"
              className="py-3 px-4 rounded-[40px] bg-[var(--bg-yellow)] text-var(--text-dark)] text-sm"
            >
              {t("Landing.Header.signIn")}
            </Link> */}
        <div className="relative max-w-max flex items-center gap-2">
        <Link
          to="/Auth/SingIn"
          className="absolute -left-36 w-[134px] h-[44px] hidden lg:flex justify-center items-center space-x-2 py-3 px-4 border border-[#FFF133] rounded-[40px] text-[#FFF133] text-[15px] leading-[24px] font-[700]"
        >
          Inicia sesión
        </Link>
        <Link
          to="/Auth/SingUp"
          className="w-[117px] h-[44px] hidden lg:flex justify-center items-center space-x-2 py-3 px-4 bg-[#FFF133] text-[#161616] rounded-[40px] text-[15px] leading-[24px] font-[700]"
        >
          Regístrate
        </Link>

        </div>
        {/* </div> */}
        {/* <div
            className="relative w-[80px] flex items-center cursor-pointer"
            onClick={() => setIsMenu(!isMenu)}
            ref={menuRef}
          >
            <div className="relative w-[40px] h-full flex">
              <img
                src={WordLenguaje}
                alt=""
                className="w-full h-full object-cover"
              />
              <span
                className={`text-[10px] absolute ${
                  i18n.language === "pt" ? "right-[2px]" : "right-0"
                }  top-1/2 -translate-y-1/2 text-[var(--text-yellow)] mt-[1px]`}
              >
                {i18n.language === "es"
                  ? "Es"
                  : i18n.language === "en"
                  ? "En"
                  : "Pt"}
              </span>
            </div>
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`w-5 h-5 ${
                isMenu ? "rotate-0" : "rotate-180"
              } mt-[2px] transition-all duration-300 cursor-pointer`}
              onClick={() => setIsMenu(!isMenu)}
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M17 15L12 10L7 15"
                  stroke="var(--text-yellow)"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
              </g>
            </svg>
            <AnimatePresence>
              {isMenu && (
                <motion.ul
                  className="bg-[var(--bg-dark)] absolute w-28 z-50 -left-5 top-12 rounded-lg p-2 flex flex-col justify-between gap-1"
                  initial={{ opacity: 0, y: "-50%" }}
                  animate={{ opacity: 1, y: "0%" }}
                  exit={{
                    opacity: 0,
                    y: "-50%",
                    transition: { duration: "0.35" },
                  }}
                  transition={{
                    type: "spring",
                    stiffness: "100",
                    duration: "0.75",
                  }}
                >
                  <li
                    className={`py-1 px-2 flex items-center gap-4 rounded-md text-[--text-body] text-sm hover:bg-[--dark-gray] ${
                      i18n.language === "es" && "bg-[--dark-gray]"
                    } cursor-pointer text-[var(--text-yellow)]`}
                    onClick={() => {
                      toogleLenguaje("es");
                      setIsMenu(false);
                    }}
                  >
                    Español
                  </li>
                  <li
                    className={`py-1 px-2 flex items-center gap-4 rounded-md text-[--text-body] text-sm hover:bg-[var(--text-body)] ${
                      i18n.language === "en" && "bg-[var(--text-body)]"
                    } cursor-pointer text-[var(--text-yellow)]`}
                    onClick={() => {
                      toogleLenguaje("en");
                      setIsMenu(false);
                    }}
                  >
                    English
                  </li>
                  <li
                    className={`py-1 px-2 flex items-center gap-4 rounded-md text-[--text-body] text-sm hover:bg-[var(--text-body)] ${
                      i18n.language === "pt" && "bg-[var(--text-body)]"
                    } cursor-pointer text-[var(--text-yellow)]`}
                    onClick={() => {
                      toogleLenguaje("pt");
                      setIsMenu(false);
                    }}
                  >
                    Portugués
                  </li>
                </motion.ul>
              )}
            </AnimatePresence>
          </div> */}
        {/* </div> */}

        {/* mobile menu */}
        <div className="md:hidden block">
          <button onClick={handleMenuToggler}>
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M4 6H20M4 12H14M4 18H9"
                  stroke="#eff0f1"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </button>
        </div>
      </nav>

      {/* nav for mobile */}
      <div
        className={`px-4 bg-[--bg-dark] py-5 rounded-sm ${
          isMenuOpen ? "" : "hidden"
        }`}
      >
        <ul>
          {navItems.map(({ path, title }, index) => (
            <li
              key={index}
              className="text-base text-[--text-body] first:text-white py-1"
            >
              <NavLink
                to={path}
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-[var(--Lime-300)]" : ""
                }
              >
                {title}
              </NavLink>
            </li>
          ))}
          <li className="text-white py-1">
            <Link to="/Auth">{t("apps.Landing.Header.Header.Login")}</Link>
          </li>
          <li className="text-white py-1">
            <Link to="/Auth">{t("apps.Landing.Header.Header.Login")}</Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
